import axios from "axios";

export interface Contact {
  subject: string
  name: string
  email: string
  document: string
  phone: string
  zip: string
  message: string
}

export class AxiosSendContact {
  async post (contact: Contact): Promise<void> {
    await axios.post('https://api.integracao.casadebraganca.com/send-contact/vitoria', contact);
  }
}