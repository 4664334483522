import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import * as S from './style';
import saude from '../../assets/img/beneficio-s.png'
import educacao from '../../assets/img/beneficio-e.png'
import lazer from '../../assets/img/beneficio-l.png'
import timeBeneficio from '../../assets/img/time-beneficio.png'
import saudeBeneficio from '../../assets/img/saude-beneficio.png'
import educacaoBeneficio from '../../assets/img/educacao-beneficio.png'
import lazerBeneficio from '../../assets/img/lazer-beneficio.png'
import background from '../../assets/img/beneficios-bk.png';
import backgroundMobile from '../../assets/img/beneficios-bk-mobile.png';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

export default function Beneficios(){
    const [viewInfo, setViewInfo] = useState('');

    const isMobile = document.documentElement.clientWidth <= 768;

    const checkMobileBox = (name: string, invert?: boolean) => {
        if (isMobile) {
            const comparison = viewInfo === name;
            if (invert) {
                return !comparison;
            }
            return comparison;
        }
        return invert ?? true;
    }

    const handleClick = (e: any) => {
        console.log('entrou click');
        console.log(e.target.id);
        console.log(e);
        console.log(e.currentTarget.id);
        if (isMobile) {
            return setViewInfo(e.currentTarget.id);
        }
        return setViewInfo('');
    };
    console.log({ viewInfo });
    console.log(checkMobileBox('saude', false))
    
    return<>
        <Header translucent={false} />
        <S.Titulo>
            <h1>Conheça os benefícios</h1>
            <picture>
                <source srcSet={backgroundMobile} media="(max-width: 48em)" />
                <source srcSet={background} />
                <img src={background} alt="fundo" />
            </picture>
        </S.Titulo>

        <S.Beneficios>
            <Container>
                <Row>
                    <div className='boxes'>
                        <div id="saude" className='box saude' onClick={handleClick} >
                            <div className='on-saude'>
                                <img src={saude} alt="" />
                                <h1>SAÚDE</h1>
                            </div>
                            {checkMobileBox('saude', isMobile ? false : true) && <div className='off-saude'>
                                <p>O Cartão de TODOS tem clínicas e laboratórios parceiros em todo o Brasil para consultas em diversas especialidades e mais de 1.500 exames laboratoriais e de imagem.</p>
                            </div>}
                        </div>
                        <div id="educacao" className='box educacao' onClick={handleClick}>
                            <div className='on-educacao'>
                                <img src={educacao} alt="" />
                                <h1>EDUCAÇÃO</h1>
                            </div>
                            {checkMobileBox('educacao', isMobile ? false : true) && <div className='off-educacao'>
                                <p>Toda a sua família também tem acesso a uma rede de ensino qualificado, com ótimos descontos em escolas, faculdades, cursos técnicos e profissionalizantes.</p>
                            </div>}
                        </div>
                        <div id="lazer" className='box lazer' onClick={handleClick}>
                            <div className='on-lazer'>
                                <img src={lazer} alt="" />
                                <h1>LAZER</h1>
                            </div>
                            {checkMobileBox('lazer', isMobile ? false : true) && <div className='off-lazer'>
                                <p>E quem tem saúde pode viajar tranquilo e se divertir com descontos incríveis em pousadas, colônias de férias, academias e clubes.</p>
                            </div>}
                        </div>
                    </div>
                </Row>
            </Container>
        </S.Beneficios>

        <S.Descontos>
            <h1>Descontos Especiais do vitória.</h1>
            <Container>
                <Row>
                    <Col>
                        <div className='especiais'>
                            <img src={timeBeneficio} alt="" />
                            <div>
                                <h4>Especiais</h4>
                                <p>Em breve compartilharemos descontos em benefícios especiais  para os filiados ao Cartão de TODOS Vitória.</p>
                            </div>
                        </div>
                        <div className='especiais'>
                            <img src={saudeBeneficio} alt="" />
                            <div>
                                <h4>Saúde</h4>
                                <p>São mais de 250 clínicas médicas e odontológicas e mais de 1.500 exames a preços acessíveis para o seu bolso, além de descontos especiais em farmácias.</p>
                            </div>
                        </div>
                        <div className='especiais'>
                            <img src={educacaoBeneficio} alt="" />
                            <div>
                                <h4>Educação</h4>
                                <p>Você pode impulsionar sua carreira com os cursos profissionalizantes e preparatórios do Refuturiza, além de descontos em mais de 67 universidades, escolas de idiomas e cursos técnicos.</p>
                            </div>
                        </div>
                        <div className='especiais'>
                            <img src={lazerBeneficio} alt="" />
                            <div>
                                <h4>lazer</h4>
                                <p>Aproveite descontos e preços especiais em viagens, hotéis, parques, lojas, restaurantes, academias e clubes.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </S.Descontos>

        <Footer />
    </>
}