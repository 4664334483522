import { useState } from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";

import * as S from "./style";
import logoCartao from "../../assets/img/logo-cartao.png";
import menu from "../../assets/img/menu.png";
import close from "../../assets/img/close.png";
import { DefaultButton } from "../DefaultButton";
import useScroll from "../../hooks/useScroll";

type IHeader = {
  translucent?: boolean;
};

export default function Header(props: IHeader) {
  const { isScrolling } = useScroll(300);
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <>
      <S.Header
        translucent={
          props.translucent  ?? (document.documentElement.clientWidth <= 480
            ? false
            : !isScrolling)
        }
      >
        <div className="header-container">
          <Row>
            <div className="grid">
              <Link to="/">
                <img src={logoCartao} alt="logo cartão de todos" />
              </Link>
              <div>
                <div className="desk">
                  <S.HeaderLink to="/contato">
                    <p>Contato</p>
                  </S.HeaderLink>
                  <a className="btn-desk" href="http://vendas.cartaodetodosvitoria.com.br/">
                    <DefaultButton>Solicite seu Cartão</DefaultButton>
                  </a>
                </div>
              </div>
              <div className="mobile">
                <div className="bt-mobile" onClick={handleToggle}>
                  <img src={menu} alt="botão menu" />
                </div>
              </div>
            </div>
          </Row>
        </div>

        <S.Modal className={isActive ? "aberto" : ""}>
          <img src={close} alt="botão fechar" onClick={handleToggle} />
          <div className="bts">
            <Link to="/beneficios">
              <p>Benefícios</p>
            </Link>
            <Link to="/parceiros">
              <p>Parceiros</p>
            </Link>
            <Link to="/contato">
              <p>Contato</p>
            </Link>
            <a href="http://vendas.cartaodetodosvitoria.com.br/">
              <button>Solicite seu Cartão</button>
            </a>
          </div>
        </S.Modal>
      </S.Header>
    </>
  );
}
