import styled from "styled-components";

export const Rede = styled.div`
    text-align: center;
    padding: 50px 0;
    padding-top: 150px;
    h1{
        color: #DA3832;
        font-family: 'ubuntu';
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 60px;
        @media (max-width: 768px){
            font-size: 17px;
            line-height: 20px;
            margin-bottom: 30px;
        }
    }
    p{
        font-family: 'ubuntu';
        font-weight: 500;
        color: #DA3832;
        font-size: 14px;
        line-height: 18px;
        margin-top: 15px;
        span{
            font-weight: bold;
        }
    }
    @media (max-width: 48rem) {
        .col-md-2 > img {
            margin-top: 1.25rem;
        }
    }
`;

export const Unidades = styled.div`
    padding: 30px 0;
    .unidade{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 366px;
        text-align: center;
        border: solid 1px #CCCCCC;
        padding: 20px 40px;
        margin: 20px 0;
        h4{
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            margin: 20px 0;
        }
        a{
            color: #000;
            font-family: 'ubuntu';
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-decoration: none;
        }
        button, a.local {
            min-width: 200px;
            line-height: 24px;
            border-radius: 30px;
            background-color: #F6D617;
            border: none;
            color: #000;
            margin-top: 20px;
            position: relative;
            padding: 10px 0;
            img{
                position: absolute;
                right: 20px;
            }
        }
        .logo {
            display: block;
            margin: 0 auto;
            overflow: hidden;
            border: 1px solid #ccc;
            border-radius: 50%;
            width: 92px;
            height: 92px;
        }
    }
`;