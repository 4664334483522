import { ReactElement } from "react";
import { Accordion } from "react-bootstrap";

const campaignData = {
    teamName: 'Vitória',
    salesUrl: '',
    imgs: {
        preco: {
            bgMobile: '',
            bgDesktop: '',
            cartaoPreco: ''
        }
    }
}

export function FaqSection (): ReactElement {
  return <Accordion>
      <Accordion.Item eventKey="0" className='acordion-item'>
          <Accordion.Header>
              <div className='acordion-titulo'>Como pedir meu Cartão de Todos {campaignData.teamName}?</div>
          </Accordion.Header>
          <Accordion.Body>
              Pelo Site: entre em "Solicite seu cartão" preencha o campo "CEP" e siga os processos e passos; Pelo Telefone: 4003-5801; ou Pessoalmente: nas Franquias do Cartão de TODOS mais próxima da sua residência.
          </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='acordion-item'>
          <Accordion.Header>
              <div className='acordion-titulo'>Quanto custa o Cartão de TODOS {campaignData.teamName}?</div>
          </Accordion.Header>
          <Accordion.Body>
          O valor da adesão é de R$29,70, a mensalidade tem o valor de R$29,70 e a anuidade é de R$30 podendo ser dividida em até 12 vezes de R$2,50.
          </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className='acordion-item'>
          <Accordion.Header>
              <div className='acordion-titulo'>Já tenho o Cartão de TODOS, como eu faço para transferir para o Cartão de Todos {campaignData.teamName}?</div>
          </Accordion.Header>
          <Accordion.Body>
              Vá até a franquia do Cartão de TODOS mais próxima de sua residência e solicite o upgrade para o seu plano. O filiado pagará pelo upgrade o valor da anuidade e a nova carteirinha. A anuidade possui o valor de R$30, podendo ser dividida em até 12 vezes de R$2,50 e a carteirinha tem o valor sugerido de R$10.
          </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3" className='acordion-item'>
          <Accordion.Header>
              <div className='acordion-titulo'>Como o Cartão de Todos {campaignData.teamName} funciona?</div>
          </Accordion.Header>
          <Accordion.Body>
          O Cartão de TODOS é uma empresa que trabalha no ramo de cartões de desconto, oferecendo a intermediação de descontos entre pessoas físicas (aderentes) e pessoas jurídicas (empresas parceiras). Ao aderir ao Cartão de TODOS e realizar o pagamento de suas mensalidades, o cliente passa a ter benefícios de descontos em uma vasta rede de empresas parceiras nas áreas de saúde, educação e lazer, espalhadas em todo o brasil. Um ótimo exemplo destes benefícios são os custos de consultas nas clínicas parceiras - R$ 26 clínico geral e R$ 35 demais áreas, além de descontos especiais em procedimentos, tratamentos odontológicos completos e exames. O cliente paga uma taxa de emissão do cartão no valor de R$ 29,70 (paga no ato do contrato) e as mensalidades são no valor de R$ 29,70. A anuidade é paga pelo cliente em até 12 parcelas de R$ 2,50. Você pode colocar como dependentes cônjuge e filhos até 21 anos.
          </Accordion.Body>
      </Accordion.Item>

  </Accordion>
}