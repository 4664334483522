import { Col, Container, Row } from 'react-bootstrap';

import * as S from './style';
import logo from '../../assets/img/time.png'
import face from '../../assets/img/face.png'
import insta from '../../assets/img/insta.png'
import tw from '../../assets/img/tw.png'
import g from '../../assets/img/g.png'
import { DefaultButton } from '../DefaultButton';

export default function Footer(){
    return<>
        <S.Footer>
            <Container>
                <Row>
                    <Col md='3'>
                        <img src={logo} alt="logo vitória" />
                    </Col>
                    <Col md='3'>
                        <p>CENTRAL DE VENDAS</p>
                        <a href="tel:08007292078">0800 729 2078</a>
                    </Col>
                    <Col md='3'>
                        <p>SAC</p>
                        <a href="tel:08002838916">0800 283 8916</a>
                    </Col>
                    <Col md='3'>
                        <div className='social'>
                            <a href="/" target='blank'>
                                <img src={face} alt="logo facebook" />
                            </a>
                            <a href="/" target='blank'>
                                <img src={insta} alt="logo instagram" />
                            </a>
                            <a href="https://twitter.com/cdtvitoria" target='blank'>
                                <img src={tw} alt="logo twitter" />
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className='txt-footer'>
                        <p>* Cartão de desconto não é plano de saúde, não garante e não se responsabiliza pelos serviços oferecidos e pelo pagamento das despesas, nem assegura desconto em todos os serviços obrigatoriamente garantidos por plano de saúde. Tudo o que o cliente usar ou comprar será por ele pago ao prestador, assegurando-se apenas os preços e descontos que constam na relação de empresas e serviços conveniados disponibilizada no site www.cartaodetodos.com.br. O atendimento será em até 15 dias, mediante disponibilidade na clínica. Consulte a clínica parceira para as especialidades disponíveis e condições para marcar as consultas.</p>
                    </div>
                    <div className='credits'>
                        <p>Um produto</p>
                        <S.Button href="https://www.cartaodetodos.com.br/">
                            <DefaultButton>
                                Cartão de TODOS ©
                            </DefaultButton>
                        </S.Button>
                        <S.Button href="https://cartaodetodos.com.br/wp-content/uploads/2023/04/Contrato-de-AdCDT2023.pdf">
                            <DefaultButton>
                                Contrato de adesão
                            </DefaultButton>
                        </S.Button>
                        <img src={g} alt="logo g" />
                    </div>
                </Row>
            </Container>
        </S.Footer>
    </>
}