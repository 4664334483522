import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useContext,
} from "react";
import { ContactFormContext, IUserInputs } from "../context/ContactFormDataContext";
import { cepMask, cpfMask, phoneMask } from "../helpers/masks";
import useAjvValidationWithCtx from "./useAjvValidationWithCtx";

function useValidInputCtx(name: keyof IUserInputs) {
  const { updateFormData, formData } = useContext(ContactFormContext);
  const { checkIsValid, isValid, resetOneInputError } = useAjvValidationWithCtx(ContactFormContext, name);

  const changeInputValue = (newValue: string | boolean) => {
    name && updateFormData((oldValues) => {
       return {
        ...oldValues,
        userInputs: {
          ...oldValues.userInputs,
          [name]: newValue,
        },
      };
    });
  };

  const onChangeCtx: ChangeEventHandler<HTMLInputElement> | ChangeEventHandler<HTMLInputElement>  = (e) => {
    const { name, value } = e.target;
    if (name === "cep" && value.length > 0) {
      return  changeInputValue(cepMask(value));
    }

    if (name === "phone" && value.length > 0) {
      return changeInputValue(phoneMask(value));
    }

    if (name === "cpf" && value.length > 0) {
      return changeInputValue(cpfMask(value));
    }
    
    if (e.target?.type === "checkbox") {
      return changeInputValue(e.target?.checked);
    }

    return changeInputValue(value);
  };

  const onFocusReset: FocusEventHandler<HTMLInputElement> = () => {
    return resetOneInputError();
  };

  const onBlurCheck: React.FocusEventHandler<HTMLInputElement> = () => {
    checkIsValid();
  };

  return {
    isValidInput: isValid,
    error: formData.errors[name],
    inputParams: {
      onChange: onChangeCtx,
      onBlur: onBlurCheck,
      onFocus: onFocusReset,
    } as Partial<React.ParamHTMLAttributes<HTMLInputElement>>,
  };
}

export default useValidInputCtx;
