import axios from "axios";

export interface Especialidade {
  Codigo: number,
  Nome: string,
}

export class AxiosRequestEspecialidade {
  async get (): Promise<Especialidade[]> {
    const response = await axios.get('https://api.cdt.casadebraganca.com/ListarCategorias');
    return response.data.ObjetoRetorno.map((o:any) => o as Especialidade);
  }
}