import styled from "styled-components";

import rubro from '../../assets/img/rubro.png';
import mobile from '../../assets/img/rubro-mobile.png';
import acordion from '../../assets/img/acordion.png';

export const ModalHome = styled.div`
        font-family: "Ubuntu", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 9999999999;
    &.close{
        display: none;
    }
    .bem-vindo{
        display: flex;
        flex-direction: column;
        width: 79.6vw;
        max-width: 67.5rem;
        height: clamp(31.25rem , 45vw, 38.438rem);
        background-image: url(${rubro});
        background-repeat: no-repeat;
        background-position: center;
        background-size: inherit;
        margin-top: 9.6rem;
        max-height: 38.75rem;
    }
    .icon-mobile{
            display: none;
        }
        .close-modal-icon{
            width: 1.875rem;
            align-self: flex-end;
            margin-right: 1.875rem;
            margin-top: 1.875rem;
        }
        img{
            height: auto;
            cursor: pointer;
        }
        picture {
            display: flex;
            flex-direction: column;
        }
    .txt-modal{
        max-width: 40vw;
        margin-top: 2rem;
        & > h1, p, button {
            margin-left: 3.4rem;
        }
        h1{
            min-width: 40ch;
            font-family: "Arvo", serif;
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 3rem;
            color: #DA3832;
        }
        p{
            max-width: 38ch;
            font-family: sans-serif;
            margin-top: 1.2rem;
            font-weight: 400;
            font-size: min(1.611vw, 1.375rem);
            line-height: 2.2rem;
            color: #000;
        }
        button{
            font-family: "Arvo", serif;
            width: 28.625rem;
            height: 5.3rem;
            border-radius: 50px;
            border: none;
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 3rem;
            margin-top: 2.5rem;
            text-transform: uppercase;
        }
    }
    @media(max-width: 60em) {
        & > div {
            display: flex;
        }
        .bem-vindo{
            background-image: url(${mobile});
            align-self: flex-start;
            margin-top: 5.2rem;
            width: 18.8rem;
            height: 27.1rem;
            p {
                margin-left: 0;
                max-height: 100%;
            }
            h1 {
                margin-left:  0;
            }
            button {
                margin-top: 0;
            }
        }
        .close-modal-icon{
            width: 1.3rem;
            align-self: flex-end;
            margin-right: 1.3rem;
            margin-top: 1.3rem;
            height: auto;
        }
        .txt-modal{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            max-width: 100%;
            height: 100%;
            margin-top: 0;
            & > h1, p, button {
                margin-left: 2.9rem;
            }
            button {
                font: normal normal 700 0.563rem/0.688rem "Ubuntu";
                min-width: 0;
                margin-top: 12.125rem;
                width: 7.9rem;
                height: 1.6rem;
                margin-left: 0;
            }
            p {
                font-family: "Ubuntu", sans-serif;
                font-size: 0.813rem;
                text-align: left;
                line-height: 1rem;
                max-width: 30ch;
            }
            h1 {
                font-family: "Ubuntu", sans-serif;
                font-size: 1.06rem;
                line-height: 0.7rem;
                margin-top: 1rem;
            }
            a{
            align-self: center;
            width: 150px;
            height: 35px;
            font-size: 1.1rem;
            line-height: 2.2rem;
            }
        }
}`;

export const Banner = styled.div`
    box-sizing: border-box;
    width: 100%;
    position: relative;
    min-height: 32.125rem;
    .carousel{
        width: 100vw;
    }
    .carousel-item {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 33.125rem;
        img{
            position: sticky;
            top: 0;
            left: 0;
            object-fit: cover;
            min-height: 31.25rem;
            position: absolute;
            width: 100vw;
        }
    }
    .texto{
        margin: 9.125rem;
        position: relative;
        width: clamp(18.75rem, 39.312vw, 60ch);
        h1{
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: clamp(1.375rem, 2.928vw, 2.5rem);
            line-height: 2.875rem;
            color: #F6D617;
            margin-bottom: 0.7rem;
        }
        p{
            font-family: 'ubuntu';
            font-weight: 400;
            font-size: clamp(0.813rem, 1.757vw, 1.5rem);
            line-height: clamp(1rem, 2.2vw,1.875rem);
            color: #Fff;
        }
    }
    @media (max-width: 30em) {
        min-height: 26rem;
        .carousel-item {
            min-height: 23.438rem;              
            img {
                min-height: 21.875rem;              
            }
            .texto {
                align-self: flex-start;
                padding-top: 1.438rem;
                text-align: center;
                margin: auto;
                h1 {
                    margin-bottom: 0.2rem;
                    margin-top: 0.1rem;
                }
            }
        }
        padding-top: 3.75rem
    }
`;
export const Beneficios = styled.div`
    border: 0;
    max-width: 100vw;
    text-align: center;
    h1{
        font-family: 'ubuntu';
        font-weight: bold;
        font-size: clamp(0.875rem, 1.25vw, 1.25rem);
        line-height: 1.5rem;
        margin-top: 0.75rem;
    }
    p{  
        font-family: 'ubuntu';
        margin-top: 0.5rem;
        font-weight: 300;
        font-size: clamp(0.813rem, 0.875vw, 0.875rem);
        line-height: 1rem;
        width: 23ch;
        @media(max-width: 48rem) {
            width: 17ch;
        }
    }
    a {
        display: block;
        button {
            min-width: 12.5rem;
            min-height: 2.75rem;
            background-color: #F6D617;
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 1.25rem;
            color: #000;
            margin: 0 auto;
            border: none;
            border-radius: 30px;
            margin-top: 3.125rem;
            @media (max-width: 48rem) {
                margin-top: 1.25rem;
                min-width: 18.75rem;
                min-height: 3rem;
            }
        }
    }
    .container {
        max-width: 60rem;
        padding-top: 3rem;
        @media (max-width: 30em) {
            padding-top: 0.625rem;
            max-width: 18.875rem;
        }
        .row > * {
            padding-left: 0;
        }
        @media (max-width: 48em) and (min-width: 30em) {
            max-width: 25.875rem;
        }
    }

    .saude {
        img {
            height: 3.313rem;
            width: auto;
        }
        p {
            margin-top: 0.4rem;
        }
    }
    .educacao {
        img {
            height: 3.313rem;
            width: auto;
        }
        h1 {
            line-height: 1.5rem;
        }
        p {
            margin-top: 0.4rem;
        }
    }
    .lazer {
        img {
            height: 3.313rem;
            width: auto;
        }
        h1 {
            line-height: 1.4rem;
        }
    }
    .especiais {
        img {
            height: 3.313rem;
            width: auto;
        }
        h1 {
            line-height: 1.45rem;
        }
        p {
            margin-top: 0.4rem;
        }
    }
    .col-md-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        @media(max-width: 48rem) {
            width: 50%;
            img {
                height: 1.906rem;
            }
        }
    }
    @media (max-width: 48em) {
        .lazer, .especiais {
            img {
                margin-top: 1.8rem;
            }
        }
    }
`;

export const Mensalidade = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    h1{
        color: #fff;
        font-family: 'ubuntu';
        font-weight: bold;
        font-size: clamp(2rem, 3.665vw, 3.125rem);
        line-height: 57px;
        width: min(43.27vw,36.942rem);
        order: 1;
        @media(max-width: 48em) {
            display: none;
        }
    }
    .col-md-6 > p {
        width: min(43.27vw, 36.942rem);
        margin-top: 1rem;
        color: #fff;
        font-family: 'ubuntu';
        font-weight: 500;
        font-size: min(2vw, 1.25rem);
        line-height: 24px;
        order: 2;
        @media(max-width: 48em) {
            display: none;
        }
    }
    .col-md-6 > picture > img {
        object-fit: none;
        position: relative;
    }
    .subBt {
            order: 4;
            button {
            min-width: 21.875rem;
            font-size: 1.25rem;
            margin-top: 3.125rem;
            text-transform: uppercase;
            padding: 21px 0;
        }
        @media(max-width: 80rem) {
            display: flex;
            width: 100%;
            text-decoration: none;
            button {
                min-width: 18.813rem;
            }
        }
    }
    .container {
        max-width: 100%;
        min-height: 56.25rem;
        position: relative;
        padding: 0;
        picture {
                order: 5;
            img {
                position: absolute;
                object-fit: cover;
                object-position: center;
                top: 0;
                left: 0;
            }
        }
        @media(max-width: 48em) {
            min-height: 17.125rem;
            picture {
                order: 3;
                img {
                    position: relative;
                    display: block;
                    object-fit: contain;
                    height: auto;
                }
            }
            div.row {
                background-color: #DA3832;
            }
        }
        .col-md-6 {
            display: flex;
            flex-direction: column;
            width: 100%;
            picture {
                position: absolute;
                left: max(48rem, 50vw);
                top: 14rem;
                @media (max-width: 80em) {
                    position: relative;
                    top: 0;
                    left: 0;
                }
                img {
                    object-fit: contain;
                    width: clamp(23.5rem, 44.3632vw, 37.876rem);
                    position: relative;
                    height: auto;
                    margin: 0 auto;
                }
            }
            @media(max-width: 80em) {
                align-items: center;
            }
        }
        div.row {
            order: 3;
            position: relative;
            justify-content: space-between;
            .row {
                margin-top: 3rem;
                padding-right: 2.625rem;
                margin-left: 0;
                height: 13.625rem;
                max-width: 43.75rem;
                width: 50%;
            }
            @media (max-width: 80rem) {
                > .col-md-6 {
                    margin-left: 0;
                    margin-right: 0;
                    margin-top: 0;
                    height: 100%;
                }
                div.row {
                    height: 31.25rem;
                    padding-right: 0;
                    max-width: 100%;
                    width: 100%;
                    flex-direction: column;
                    flex-wrap: nowrap;
                }
            justify-content: center;
            align-items: center;
            h1, p {
                text-align: center;
            }
            }
        }
        .row:nth-child(2) {
            padding-top: 12.5rem;
            padding-left: 140px;
            @media (max-width: 80rem) {
                padding-left: 0;
                padding-top: 20rem;
                height: 100%;
            }
            @media (max-width: 48rem) {
                padding-left: 0;
                padding-top: 0;
                height: 100%;
            }
        }
    }
    img{
        width: 100%;
        height: 100%;
    }
    fieldset {
        border: 7px solid rgba(246,214,23,1.0);
        padding: 10px;
        border-radius: 14px;
        legend {
            width: 15ch;
            margin: 0 auto;
            text-transform: uppercase;
            float: none;
            color: #fff;
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            span {
                width: 11ch;
                margin: 0 auto;
            }
        }
        @media (max-width: 80rem) {
            margin-top: 3.5rem;
        }
    }
    .preco{
        position: relative;
        min-height: 13.625rem;
        max-width: 17rem;
        background-position: center;
        h2{
            position: absolute;
            top: 0;
            background: transparent;
            color: #fff;
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 20px;
            line-height: 1;
            margin:-10px 0 0;
            padding: 0 3px;
            text-transform: uppercase;
            position: relative;
            float: left;
            bottom: 0.625rem;
            text-align: center;
        }
        .consultas{
            display: flex;
            justify-content: space-evenly;
            margin-top: 30px;
            h3{
                color: #fff;
                font-family: 'ubuntu';
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                min-width: 94px;
            }
            p{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.625rem;
                color: #fff;
                font-family: 'ubuntu';
                font-weight: bold;
                font-size: 1.688rem;
                line-height: 1.938rem;
                position: relative;
                bottom: 0.813rem;
                .currency-unit {
                    margin-top: 0.5rem;
                    align-self: flex-start;
                    font-size: 1.688rem;
                    line-height: 1.938rem;
                }
                span{
                    display: inline;
                    font-size: 3.125rem;
                    line-height: 3.125rem;
                }
            }
        }
    }
    .centro{
        max-width: 15rem;
        text-align: center;
        p{
            color: #fff;
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 39px;
            line-height: 44px;
            margin-top: 1.125rem;
            .preco-g{
                font-size: 76px;
                position: relative;
                top: 10px;
            }
            .preco-centavo{
                font-size: 29px;
            }
        }
        h3{
                color: #fff;
                font-family: 'ubuntu';
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                margin-top: 25px;
            }
    }
`;

export const Rede = styled.div`
    background-color: #DA3832;
    text-align: center;
    .container {
        max-width: 100%;
    .row {
        overflow: hidden;
        max-width: 100%;
        display: flex;
        margin-inline: 0;
        div {
            flex-shrink: 0;
            flex-wrap: wrap;
            flex-grow: 1;
        }
        .col-md-2 {
            img {
                margin-top: 2.5rem;
            }
            p {
                margin-top: 1rem;
            }
        }
        @media(max-width: 60rem) and (min-width: 48rem) {
            .col-md-2 {
                width: 30%;
            }
        }
    }
}
    h1{
        color: #F6D617;
        font-family: 'ubuntu';
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 2.5rem;
        text-align: center;
        text-transform: uppercase;
        padding-top: 2rem;
        margin-bottom: 3.75rem;
        span{
            color: #fff;
        }
        @media (max-width: 48rem){
            font-size: 1.063rem;
            line-height: 1.25rem;
            margin-bottom: 1.875rem;
        }
    }
    p{
        font-family: 'ubuntu';
        font-weight: 500;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        span{
            font-weight: bold;
        }
    }
`;

export const Perguntas = styled.div`
    background-image: url(${acordion});
    background-repeat: no-repeat;
    background-color: #DA3832;
    background-size: contain;
    padding: 150px 0;
    @media (max-width: 768px){
        padding: 30px 0;
        .accordion-body{
            font-size: 14px;
        }
    }
    .texto{
        width: 300px;
        margin: 0 auto;
        h1{
            font-family: 'ubuntu';
            font-weight: bold;
            color: #fff;
            font-size: 40px;
            line-height: 46px;
            @media (max-width: 768px){
                font-size: 17px;
                line-height: 19px;
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }
    .acordion-item{
        margin-bottom: 30px;
        border-radius: 10px;
        border: none;
        background-color: #ffffffb3;
        font-size: 0.875rem;
        font-family: "Ubuntu", sans-serif;
        line-height: 1rem;
        button{
            border-top-left-radius: 10px!important;
            border-top-right-radius: 10px!important;
            border-bottom-left-radius: 10px!important;
            border-bottom-right-radius: 10px!important;
            background-color: #ffffff00;
            box-shadow: unset;
            :hover{
                font-weight: 900;
            }
            :after {
                background-image: none;
            }
        }
        @media (max-width: 48rem) {
            .accordion-body{
                padding-left: 2rem;
                padding-top: 0;
            }
        }
    }
    .acordion-titulo{
        text-align: center;
        width: 100%;
        @media (max-width: 768px){
            font-size: 0.875rem;
            text-align: left;
            margin-left: 0.2rem;
        }
        :not(.collapsed) {
            color: #212529;
        }
    }
`;

export const Unidades = styled.div`
    padding: 30px 0;
    .unidade{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 366px;
        text-align: center;
        border: solid 1px #CCCCCC;
        padding: 20px 40px;
        margin: 20px 0;
        h4{
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            margin: 20px 0;
        }
        a{
            color: #000;
            font-family: 'ubuntu';
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-decoration: none;
        }
        button, a.local {
            min-width: 200px;
            line-height: 24px;
            border-radius: 30px;
            background-color: #F6D617;
            border: none;
            color: #000;
            margin-top: 20px;
            position: relative;
            padding: 10px 0;
            img{
                position: absolute;
                right: 20px;
            }
        }
        .logo {
            display: block;
            margin: 0 auto;
            overflow: hidden;
            border: 1px solid #ccc;
            border-radius: 50%;
            width: 92px;
            height: 92px;
        }
    }
`;