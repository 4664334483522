import React, {
  useContext,
  SelectHTMLAttributes,
  ChangeEventHandler,
} from "react";
import {
  ContactFormContext,
  IFormData,
} from "../../context/ContactFormDataContext";
import useValidInputCtx from "../../hooks/useValidInputCtx";
import * as S from "./style";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: keyof IFormData["userInputs"];
}

function CustomSelect({ name, ...customParam }: SelectProps) {
  const { formData } = useContext(ContactFormContext);
  const {
    inputParams: { onChange, onFocus, onBlur },
    error,
  } = useValidInputCtx(name);

  return (
    <>
      <S.Select
        className={error ? "input-error" : ""}
        name={name}
        onChange={onChange as unknown as ChangeEventHandler}
        onFocus={onFocus as unknown as ChangeEventHandler}
        onBlur={onBlur as unknown as ChangeEventHandler}
        value={formData.userInputs[name]}
        {...customParam}
      >
        <option value="" disabled hidden>
          Selecione o assunto
        </option>
        <option value="solicitacao">Solicitação</option>
        <option value="reclamacao">Reclamação</option>
        <option value="informacao">Informação</option>
        <option value="outros">Outros</option>
      </S.Select>
      {error && <span className="error-icon">!</span>}
    </>
  );
}

export default CustomSelect;
