import { Col, Container, Row } from 'react-bootstrap';

import * as S from './style';
import amorSaude from '../../assets/img/amor-saude-red.png'
import cna from '../../assets/img/cna-red.png'
import drogasil from '../../assets/img/drogasil-red.png'
import estacio from '../../assets/img/estacio-red.png'
import raia from '../../assets/img/raia-red.png'
import refuturiza from '../../assets/img/refuturiza-red.png'

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Form from '../../components/Form';
import { Parceiro } from '../../domain/AxiosRequestParceiros';
import { useState } from 'react';
import ParceiroComponent from '../../components/ParceiroComponent';

export default function Home() {
    const [parceiros, setParceiros] = useState<Parceiro[]>()
    return<>
        <Header translucent={false} />
        <S.Rede>
            <h1>Nossos parceiros mais perto de você</h1>
            <Container>
                <Row>
                    <Col md='2' xs='6'>
                        <img src={amorSaude} alt="logo amor e saúde" />
                        <p>consulta a partir<br />de <span>R$ 24</span></p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={cna} alt="logo CNA" />
                        <p><span>35%</span> no 1º estágio e<br /><span>15%</span> no 2º estágio</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={drogasil} alt="logo Drogasil" />
                        <p>até <span>35%</span> em<br />medicamentos</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={estacio} alt="Logo Estácio" />
                        <p><span>40%</span> nos cursos de<br />graduação e pós-graduação</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={raia} alt="Logo Droga Raia" />
                        <p>até <span>35%</span> em<br />medicamentos</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={refuturiza} alt="Logo Refuturiza" />
                        <p><span>R$ 12,99</span> por mês</p>
                    </Col>
                </Row>
            </Container>
        </S.Rede>

        <S.Unidades>
            <Form setParceiros={setParceiros} />
            <Container>
                <Row>
                    {parceiros?.map(parceiro => <ParceiroComponent {...parceiro} />)}
                </Row>
            </Container>
        </S.Unidades>

        <Footer />
    </>
}