import { ErrorObject } from "ajv";
import { Context, useContext, useState } from "react";
import {
  IFormCtx,
  IFormData,
  INITIAL_FORM_DATA,
} from "../context/ContactFormDataContext";
import ajv from "../schemas/validation";

function useAjvValidationWithCtx(
  ctx: Context<IFormCtx>,
  field?: keyof IFormData["userInputs"]
) {
  const { updateFormData, formData } = useContext(ctx);
  const [isValid, setIsValid] = useState(false);

  const setAllInputsErrors = (errorsDict: IFormData["errors"]) => {
    return updateFormData((oldValues) => {
      return {
        ...oldValues,
        errors: errorsDict,
      };
    });
  };

  const resetAllInputErrors = () => {
    updateFormData((oldValues) => {
      return {
        ...oldValues,
        ...INITIAL_FORM_DATA.errors,
      };
    });
  };

  const setOneInputError = (newError: string) => {
    field &&
      updateFormData((oldValues) => {
        return {
          ...oldValues,
          errors: {
            ...oldValues.errors,
            [field]: newError,
          },
        };
      });
  };

  const resetOneInputError = () => {
    setIsValid(true);
    field &&
      updateFormData((oldValues) => {
        return {
          ...oldValues,
          errors: {
            ...oldValues.errors,
            [field]: "",
          },
        };
      });
  };

  const getError = (
    selectedField: keyof IFormData["userInputs"],
    errorObj: ErrorObject
  ) => {
    const testField = (field: string) => errorObj.schemaPath.includes(field);
    const hasErrorMessage =
      errorObj.keyword === "errorMessage" &&
      (testField(`${selectedField}/errorMessage`) ||
        testField(`/properties/${selectedField}/errorMessage`));
    return hasErrorMessage && (errorObj.message || "Dados inválidos");
  };

  // Get specific user input constraints ajv using #[id].
  //   [id] is equals to the [name] of property at Ctx and
  //   [name] is equals to the [name] of input too
  const checkInput = (selectedField: keyof IFormData["userInputs"]) => {
    const isValidInput = ajv.validate(
      { $ref: `order#${selectedField}` },
      formData.userInputs[selectedField]
    );
    if (!isValidInput) {
      // Get custom input error message
      const inputError = ajv.errors?.find((errorObj) => {
        return getError(selectedField, errorObj);
      });
      inputError?.message && setOneInputError(inputError.message);
      return setIsValid(false);
    }
    // Input válido
    setOneInputError("");
    return setIsValid(true);
  };

  // Check all user inputs and fill errors message
  const checkSchemaAtFormCtx = () => {
    const isValidSchema = ajv.validate("order", formData.userInputs);
    if (!isValidSchema) {
      const errorsDict = ajv.errors?.reduce((errorObj, errorRaised) => {
        const selectedField = errorRaised.instancePath.substring(
          1
        ) as keyof IFormData["errors"];
        if (
          typeof errorObj[selectedField] !== "string" ||
          errorObj[selectedField].length === 0
        )
          return {
            ...errorObj,
            [selectedField]: getError(selectedField, errorRaised),
          };
        return errorObj;
      }, INITIAL_FORM_DATA["errors"]);
      errorsDict && setAllInputsErrors(errorsDict);
      return setIsValid(false);
    }
    // Context em userInputs válido
    resetAllInputErrors();
    return setIsValid(true);
  };

  const checkIsValid = () => {
    if (field) {
      return checkInput(field);
    } else if (!field) {
      return checkSchemaAtFormCtx();
    }
  };

  return {
    isValid: isValid,
    checkIsValid,
    resetAllInputErrors,
    resetOneInputError,
  };
}

export default useAjvValidationWithCtx;
