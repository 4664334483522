import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { useState } from 'react'
import * as S from './style'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Form from '../../components/Form'
import banner1 from '../../assets/img/banner1.png'
import banner1Mobile from '../../assets/img/banner1-mobile.png'
import banner2Mobile from '../../assets/img/banner2-mobile.png'
import banner2 from '../../assets/img/banner2.png'
import { Parceiro } from '../../domain/AxiosRequestParceiros'
import ParceiroComponent from '../../components/ParceiroComponent'
import CarouselWrapper from '../../components/CarouselWrapper'
import { FaqSection } from '../../components/v2/FaqSection'
import { ParceirosSection } from '../../components/v2/ParceirosSection'
import { BeneficiosSection } from '../../components/v2/BeneficiosSection'
import { MensalidadeSection } from '../../components/v2/MensalidadeSection';

const INTERVAL = 3000;

export default function Home(){
    const [parceiros, setParceiros] = useState<Parceiro[]>();

    return<>
        <Header />

        <S.Banner>
            <CarouselWrapper interval={INTERVAL}>
                <Carousel.Item>
                    <picture>
                        <source srcSet={banner1Mobile} media="(max-width: 30em)" />
                        <source srcSet={banner1} />
                        <img src={banner1} alt="banner 1" />
                    </picture>
                    <div className='texto'>
                        <h1>Campeão na saúde</h1>
                        <p>Consultas com clínico geral por R$ 26 e demais especialidades, R$ 35. Tudo por apenas R$ 29,70 mensais para toda a família.</p>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                <picture>
                        <source srcSet={banner2Mobile} media="(max-width: 30em)" />
                        <source srcSet={banner2} />
                        <img src={banner2} alt="banner 1" />
                    </picture>
                    <div className='texto'>
                        <h1>Especiais</h1>
                        <p>Em breve compartilharemos descontos em benefícios especiais  para os filiados ao Cartão de TODOS Vitória.</p>
                    </div>
                </Carousel.Item>
            </CarouselWrapper>
        </S.Banner>

        <BeneficiosSection />

        <MensalidadeSection />

        <S.Rede>
            <h1>Aproveite a nossa rede<br />com mais de <span>12.000 parceiros.</span></h1>
            <ParceirosSection />
            <Form setParceiros={setParceiros} />
        </S.Rede>

        {parceiros && parceiros?.length > 0
            && <S.Unidades>
                <Container>
                    <Row>
                        {parceiros?.map(parceiro => <ParceiroComponent {...parceiro} />)}
                    </Row>
                </Container>
            </S.Unidades>
        }

        <S.Perguntas>
            <Container>
                <Row>
                    <Col md='6'>
                        <div className='texto'>
                            <h1>PERGUNTAS<br className='hidden-xs'/> FREQUENTES</h1>
                        </div>
                    </Col>
                    <Col md='6'>
                    <FaqSection />
                    </Col>
                </Row>
            </Container>
        </S.Perguntas>

        <Footer />
    </>
}