import { ReactElement, useMemo } from "react";
import { Button, Col } from 'react-bootstrap';
import { Parceiro } from "../../domain/AxiosRequestParceiros";
import pin from '../../assets/img/pin.png'

export default function ParceiroComponent (parceiro: Parceiro): ReactElement {
  const ContatoTelefoneFormatado = useMemo(() =>{
    return parceiro.ContatoTelefone.replace(/([0-9]{2})(9?[0-9]{4})([0-9]{4})/, '($1) $2-$3')
  }, [parceiro.ContatoTelefone])
  const NomeFantasia = useMemo(() => {
    return parceiro.NomeFantasia.toUpperCase()
  }, [parceiro.NomeFantasia])
  return <Col md='3'>
    <div className='unidade'>
        <img src={`https://arquivos.sistematodos.com.br/ArquivosLogotipoEmpresa/${parceiro.NomeImagem}`} alt="logo da unidade" className="logo" />
        <h4>{NomeFantasia} - {parceiro.Unidade}</h4>
        <p>{parceiro.Logradouro},  {parceiro.Numero}</p>
        <a href={`tel:+55${parceiro.ContatoTelefone}`}>{ContatoTelefoneFormatado}</a><br />
        <Button className="local" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${parceiro.Logradouro},+${parceiro.Unidade},+${parceiro.Bairro}`}>Localização <img src={pin} alt="pin" /></Button>
    </div>
  </Col>
}