import styled from "styled-components";

export const Principal = styled.div`
    background-color: #DA3832;
    padding: 100px 0;
    .grid{
        display: grid;
        grid-template-columns: 44% auto;
        .contato{
            width: 270px;
            margin: 0 auto;
            padding-top: 100px;
            .fones{
                margin-left: 20px;
            }
            h1{
                font-family: 'Ubuntu', sans-serif;
                font-weight: bold;
                color: #fff;
                font-size: 40px;
                line-height: 46px;
                margin-top: 50px;
            }
            p{
                font-family: 'Ubuntu', sans-serif;
                font-weight: bold;
                color: #fff;
                font-size: 1.25rem;
                line-height: 1.375rem;
                margin-top: 1.25rem;
                margin-inline: auto;
            }
            a{
                font-size: 1.25rem;
                line-height: 1.875rem;
                font-family: 'Ubuntu', sans-serif;
                display: block;
                text-decoration: none;
                color: #fff;
                margin-top: 0.563rem;
            }
        }
        .form{
            background-color: #fff;
            padding-top: 50px;
            form{
                max-width: 540px;
                margin: 0 auto;
                padding: 0 10px;
            }
            select, input, textarea{ 
                border-radius: 10px;
                width: 100%;
                background: #f0f0f0;
                padding: 15px;
                border: none;
                margin: 0 0 12px;
                color: #707070;
                font-size: 14px;
                outline: none;
            }
            button{
                min-width: 12.5rem;
                min-height: 2.75rem;
                background-color: #F6D617;
                font-family: 'ubuntu';
                font-weight: bold;
                font-size: 20px;
                color: #000;
                margin: 15px auto;
                border: none;
                border-radius: 30px;
                float: right;
            }
            .error-msg {
                font-family: "Ubuntu", sans-serif;
                display: inline-flex;
                padding-left: 3rem;
                align-items: center;
                max-width: 33.625rem;
                width: 100%;
                min-height: 2.75rem;
                background-color: #F6D617;
                margin-top: 0.625rem;
                border-radius: 1.938rem;
                font-weight: 700;
                & + .error-icon {
                    text-align: center;
                    display: block;
                    font-weight: 700;
                    background-color: rgba(255,255,255,1.0);
                    min-height: 1.375rem;
                    min-width: 1.375rem;
                    border-radius: 50%;
                    margin-top: -2.113rem;
                    padding-top: 0.125rem;
                    margin-left: 0.625rem;
                    position: absolute;
                }
            }
        }
        @media (max-width: 48em) {
            grid-template-columns: 1fr;
            padding: 0 2rem;
            .form {
                margin-inline: auto;
                max-width: 25rem;
                background-color: rgba(218,56,50,1.0);
                button {
                    display: block;
                    width: 100%;
                    margin-top: 2rem;
                    margin-left: auto;
                    margin-right: auto;
                    float: unset;
                }
            }
            .contato {
                text-align: center;
                padding-bottom: 30px;            
            }
        }
    }`;

export const SuccessMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`