import { Container, Row } from 'react-bootstrap';

import * as S from './style';
import { AxiosRequestEspecialidade, Especialidade } from '../../domain/AxiosRequestEspecialidade';
import { useCallback, useEffect, useState } from 'react';
import { AxiosRequestParceiros, Parceiro } from '../../domain/AxiosRequestParceiros';
import { AxiosRequestUF } from '../../domain/AxiosRequestUF';
import { AxiosRequestCidade } from '../../domain/AxiosRequestCidade';

export interface ResponseDTO {
    setParceiros: (parceiros: Parceiro[]) => void
}

export default function Form({ setParceiros }: ResponseDTO){
    const [especialidades, setEspecialidades] = useState<Especialidade[]>([]);
    const [especialidade, setEspecialidade] = useState<Especialidade>();
    const [ufs, setUFs] = useState<string[]>();
    const [uf, setUF] = useState<string>();
    const [cidades, setCidades] = useState<string[]>();
    const [cidade, setCidade] = useState<string>();

    const handleSubmit = useCallback(async (evt: any) => {
        const requestParceiros = new AxiosRequestParceiros();
        evt.preventDefault();
        if (!especialidade) return;
        if (!uf) return;
        if (!cidade) return;
        const request = await requestParceiros.get(especialidade, uf, cidade);
        setParceiros(request)
    }, [especialidade, uf, cidade, setParceiros]);

    const loadEspecialidades = useCallback(async () => {
        const request = new AxiosRequestEspecialidade();
        const especialidades = await request.get();
        setEspecialidades(especialidades);
    }, [])

    const loadUF = useCallback(async (evt: any) => {
        const requestUf = new AxiosRequestUF();
        const value = evt.target.value
        const especialidade = JSON.parse(value) as Especialidade;
        setEspecialidade(especialidade)
        const ufs = await requestUf.get(especialidade)
        setUFs(ufs)
    }, [])

    const loadCidades = useCallback(async (evt: any) => {
        const requestCidade = new AxiosRequestCidade();
        const uf = evt.target.value
        setUF(uf)
        if (!especialidade) return
        const cidades = await requestCidade.get(especialidade, uf);
        setCidades(cidades)
    }, [especialidade])

    useEffect(() => {
        loadEspecialidades();
    },[loadEspecialidades]);
    
    return<>
        <S.Form>
            <Container>
                <Row>
                    <form action="" onSubmit={handleSubmit}>
                        <select onChange={loadUF}>
                            <option value="">Selecione o assunto</option>
                            {especialidades.map(e => <option value={JSON.stringify(e)}>{e.Nome}</option>)}
                        </select>
                        <select onChange={loadCidades}>
                            <option value="">UF</option>
                            {ufs?.map(uf => <option value={uf}>{uf}</option>)}
                        </select>
                        <select onChange={evt => setCidade(evt.target.value)}>
                            <option value="">Selecione a cidade</option>
                            {cidades?.map(cidade => <option value={cidade}>{cidade}</option>)}
                        </select>
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.37" height="24.282" viewBox="0 0 24.37 24.282"><path id="Shape" d="M30.17,28.674l-6.981-6.961A9.777,9.777,0,1,0,21.82,23.1L28.8,30.082ZM9.951,21.067A7.958,7.958,0,0,1,21.194,9.824,7.958,7.958,0,0,1,9.951,21.067Z" transform="translate(-5.8 -5.8)"></path></svg>
                        </button>
                    </form>
                </Row>
            </Container>
        </S.Form>
    </>
}