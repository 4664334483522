import axios from "axios";
import { Especialidade } from "./AxiosRequestEspecialidade";

export class AxiosRequestUF {
  async get (especialidade: Especialidade): Promise<string[]> {
    const params = {
      codigoCategoria: especialidade.Codigo
    }
    const response = await axios.get('https://api.cdt.casadebraganca.com/ListarEstadosPorCategoria', { params });
    return response.data.ObjetoRetorno.map((o:any) => o as string);
  }
}